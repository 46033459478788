<template>
  <div
    :class="['copyright-practice-nav', isEnterIndex ? 'is-enter-index' : '']"
  >
    <div
      class="back"
      @click="
        $router.push({
          name: 'shoppingMall',
        })
      "
    >
      <img
        :src="require('assets/images/goodsGetails/detail_slide_left.png')"
        alt=""
      />
      返回
    </div>
    <ul class="copyright-practice-nav-ul">
      <li
        class="copyright-practice-nav-ul-li"
        v-for="(item, index) in navLang"
        :class="i == index ? 'color' : ''"
        :key="index"
      >
        <span @click="handleClick(index, item.name)">{{ item.title }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CopyrightPracticeNav",
  data() {
    return {
      i: 0,
      //顶部标签导航语言
      navLang: [
        // {
        //   title: "我的关注",
        //   name: "MyAttention"
        // },
        // {
        //   title: "代办委托",
        //   name: "AgencyEntrustment",
        // },
        // {
        //   title: "购物车",
        // },
        {
          title: "订单中心",
          name: "OrderCenter",
        },
        {
          title: "成语券消费",
          name: "OrderIdiom",
        },
        // {
        //   title: "我的收藏",
        //   name: "MyCollection",
        // },
      ],
    };
  },
  computed: {
    isEnterIndex() {
      return this.$route.name === "CopyrightPracticeIndex";
    },
  },
  methods: {
    handleClick(index, name) {
      this.i = index;
      this.$router.push({
        name,
      });
    },
  },
  watch: {
    $route(to, from) {
      if (
        to.path === "/copyrightService/copyrightPractice/copyrightPracticeIndex"
      ) {
        this.i = -1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.copyright-practice-nav {
  @include flex-start;
  height: 60px;

  border-bottom: 1px solid #eceff1;
  &.is-enter-index {
    border: none;
  }
  .back {
    margin: 0 30px 0 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: #666666;
    img {
      margin-right: 10px;
      height: 15px;
      width: 10px;
    }
  }
  &-ul {
    @include flex-start;
    &-li {
      cursor: pointer;
      padding: 8px 14px;
      color: #666666;
      &:first-child ~ & {
        margin-left: 30px;
      }
      span {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .color {
    background: linear-gradient(90deg, #ff6500, #ff9c00);
    border-radius: 16px;
    color: #fff;
  }
}
</style>
